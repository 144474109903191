<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'Examination', query: { examId } }">
            {{ testName }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link :to="{ name: 'Passages', query: { examId } }">
            {{ `${sectionName} Passages` }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1 slot="title">
      {{ pageTitle }}
    </h1>
    <Form
      :initPassage="passage"
      :sectionName="sectionName"
      @onSubmit="onSubmit"
    />
  </div>
</template>

<script>
import satApi from "@/apis/sat.js";
import Breadcrumb from "@/components/Breadcrumb";
import Form from "@/views/passages/Form.vue";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - ${this.CompanyName}`
    };
  },
  components: {
    Breadcrumb,
    Form
  },
  computed: {
    pageTitle() {
      return `Edit Passage ${this.passage.title || ""}`;
    },
    examId() {
      return this.$route.params.examId;
    }
  },
  data() {
    return {
      sectionName: "",
      testName: "",
      passage: {
        title: "",
        intro: "",
        content: "",
        below_content: ""
      }
    };
  },
  async created() {
    const passage = await satApi.getPassage(this.$route.params.passageId);
    this.passage = { ...passage };
    const { full_name, subjects } = await satApi.getTest(this.examId);
    this.testName = full_name;
    this.sectionName = "Reading";
  },
  methods: {
    async onSubmit(passage) {
      console.log({ passage });
      try {
        await satApi.updateSubjectPassage(
          this.$route.params.passageId,
          passage
        );
        this.$router.push({ name: "Passages" });
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>
